import { createFeature, createReducer, on } from '@ngrx/store';
import { LeadListActions } from './lead-list.actions';
import { LeadList } from 'src/app/shared/models';

export const leadListFeatureKey = 'leadList';

export interface LeadListState {
  leadList: LeadList | null;
  leadLists: LeadList[];
  loading: boolean;
  error: any;
}

export const initialState: LeadListState = {
  leadList: null,
  leadLists: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    LeadListActions.loadLeadLists,
    (state): LeadListState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadListActions.loadLeadListsSuccess,
    (state, { leadLists }): LeadListState => ({
      ...state,
      leadLists: [...state.leadLists.filter((item) => !leadLists.find(x => x.id === item.id)), ...leadLists],
      loading: false,
      error: null,
    })
  ),
  on(
    LeadListActions.loadLeadListsFailure,
    (state, { error }): LeadListState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    LeadListActions.loadLeadList,
    (state): LeadListState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadListActions.loadLeadListSuccess,
    (state, { leadList }): LeadListState => ({
      ...state,
      leadLists: [...state.leadLists.filter((item) => leadList.id !== item.id), leadList],
      leadList: leadList,
      loading: false,
      error: null,
    }),
  ),
  on(
    LeadListActions.loadLeadListFailure,
    (state, { error }): LeadListState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    LeadListActions.addLeadList,
    (state): LeadListState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadListActions.addLeadListSuccess,
    (state, { leadList }): LeadListState => ({
      ...state,
      leadLists: [...state.leadLists, leadList],
      loading: false,
      error: null,
    }),
  ),
  on(
    LeadListActions.addLeadListFailure,
    (state, { error }): LeadListState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    LeadListActions.removeLeadList,
    (state): LeadListState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadListActions.removeLeadListSuccess,
    (state, { leadListId }): LeadListState => ({
      ...state,
      leadLists: state.leadLists.filter(
        (leadList) => leadList.id !== leadListId,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    LeadListActions.removeLeadListFailure,
    (state, { error }): LeadListState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    LeadListActions.updateLeadList,
    (state): LeadListState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    LeadListActions.updateLeadListSuccess,
    (state, { leadList }): LeadListState => ({
      ...state,
      leadLists: state.leadLists.map((item) =>
        // item.id === leadList.id ? leadList : item
        item.id === leadList.id ? { ...item, ...leadList } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    LeadListActions.updateLeadListFailure,
    (state, { error }): LeadListState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const leadListFeature = createFeature({
  name: leadListFeatureKey,
  reducer,
});
